export default {
  methods: {
    closeSelect(selectEl) {
      if(!selectEl) return
      if(selectEl.length) return selectEl[0].blur()
      selectEl.blur()
    },
    closeSelectIfOpen(selectEl, {enabled}) {
      if (!selectEl || !enabled) return;
      if (selectEl.isMenuActive) {
        setTimeout(() => {
          selectEl.blur()
        }, 150);
      }
    }
  },

}
